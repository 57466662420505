<template>
  <ButterCms />
  <TransitionTest />
  <DynamicComponentTest />
</template>

<script>
import ButterCms from '@/components/demo/ButterCms'
import TransitionTest from '@/components/demo/TransitionTest'
import DynamicComponentTest from '@/components/demo/DynamicComponentTest'
export default {
  components: { ButterCms, TransitionTest, DynamicComponentTest }
}
</script>

<style>

</style>
